/* eslint-disable react/no-multi-comp */

import MatchedAuthorLink, { MATCHED_AUTHOR_SHOVELER_LINK } from './MatchedAuthorLink';

import { fetchMatchedAuthorStats } from '@/actions/MatchedAuthorActionCreators';
import { getBody } from '@/browser';
import {
  getMatchedAuthorStatFromGraphQL,
  MatchedAuthorStatRecord,
} from '@/models/author/MatchedAuthorStat';
import { nextMicroTask } from '@/utils/promise-utils';
import Api from '@/api/Api';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import GraphQLApi from '@/api/GraphQLApi';
import S2Dispatcher from '@/utils/S2Dispatcher';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import type { MatchedAuthorRecord } from '@/models/MatchedAuthor';
import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = {
  authors: Immutable.List<MatchedAuthorRecord>;
  queryString: string;
};

type State = {
  limit: number;
  authorIdToAuthorStat: Immutable.Map<string, MatchedAuthorStatRecord>;
};

const DEFAULT_DISPLAY_AUTHOR_LIMIT = 3;

// These values are copied over from match-author-shoveler.less.
// If these values are changed then they also need to be updated in the match-author-shoveler.less
const MAX_BODY_WIDTH_FOR_ONE_AUTHOR_CARD = 500;
const MAX_BODY_WIDTH_FOR_TWO_AUTHOR_CARDS = 760;

export default class MatchedAuthorShoveler extends React.Component<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    graphQLApi: PropTypes.instanceOf(GraphQLApi).isRequired,
  };

  declare context: {
    api: Api;
    dispatcher: S2Dispatcher;
    graphQLApi: GraphQLApi;
  };

  _resizeObserver: ResizeObserver;
  authorCards: React.RefObject<HTMLUListElement>;
  firstNewContent: React.RefObject<HTMLAnchorElement>;

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      limit: DEFAULT_DISPLAY_AUTHOR_LIMIT,
      authorIdToAuthorStat: Immutable.Map(),
    };
    this.authorCards = React.createRef();
    this.firstNewContent = React.createRef();
  }

  componentDidMount(): void {
    trackAnalyticsEvent(
      ShowEvent.create(EventTarget.Serp.MATCHED_AUTHOR_LIST, {
        count: this.props.authors.size,
        // event data is map of String -> String, so express the author ids as a serialized array
        // that can be parsed on the flip side
        authorIds: JSON.stringify(this.props.authors.map(a => a.id)),
      })
    );
    this._resizeObserver = new ResizeObserver(this.handleResize);
    const body = getBody(document);
    this._resizeObserver.observe(body);
    this.fetchMatchedAuthorStats();
  }

  async fetchMatchedAuthorStats(): Promise<void> {
    const authorIds = this.props.authors
      .map(({ id }) => (id ? parseInt(id, 10) : null))
      .filter((id): id is number => !!id);

    if (this.context.dispatcher.isDispatching()) {
      // Wait for any dispatches to settle before triggering a dispatch from the API call
      await nextMicroTask();
    }

    const response = await fetchMatchedAuthorStats(
      { authorIds },
      { graphQLApi: this.context.graphQLApi }
    );

    const authorIdToAuthorStat = (response.resultData.data?.authors || []).reduce(
      (map, stat) => map.set(stat.id.toString(), getMatchedAuthorStatFromGraphQL(stat)),
      Immutable.Map<string, MatchedAuthorStatRecord>()
    );
    this.setState({ authorIdToAuthorStat });
  }

  componentWillUnmount(): void {
    const body = getBody(document);
    this._resizeObserver.unobserve(body);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    // Screenreader users expect new content to be after the expand button so to limit confusion
    // we focus on the first new author card
    if (prevState.limit !== this.state.limit && this.state.limit === this.props.authors.size) {
      if (this.firstNewContent && this.firstNewContent.current) {
        this.firstNewContent.current.focus();
      } else if (this.authorCards.current) {
        const authorLinks = this.authorCards.current.querySelectorAll(
          '.' + MATCHED_AUTHOR_SHOVELER_LINK
        );
        const elem = authorLinks.item(DEFAULT_DISPLAY_AUTHOR_LIMIT);
        if (elem instanceof HTMLElement) {
          elem.focus();
        }
      }
    }
  }

  // Updates the first new author card ref based on screen size
  handleResize: ResizeObserverCallback = (entries: ResizeObserverEntry[]): void => {
    const bodyWidth = entries[0].contentRect.width;
    const authorLinks =
      this.authorCards.current?.querySelectorAll('.' + MATCHED_AUTHOR_SHOVELER_LINK) ||
      new NodeList();
    if (bodyWidth <= MAX_BODY_WIDTH_FOR_ONE_AUTHOR_CARD) {
      // @ts-expect-error -- Our flow types thinks refs are readonly, but they are not
      this.firstNewContent.current = authorLinks.item(1);
    } else if (
      bodyWidth <= MAX_BODY_WIDTH_FOR_TWO_AUTHOR_CARDS &&
      bodyWidth > MAX_BODY_WIDTH_FOR_ONE_AUTHOR_CARD
    ) {
      // @ts-expect-error -- Our flow types thinks refs are readonly, but they are not
      this.firstNewContent.current = authorLinks.item(2);
    } else {
      // sets it to null cause the fourth element isn't in authorLinks yet
      // @ts-expect-error -- Our flow types thinks refs are readonly, but they are not
      this.firstNewContent.current = null;
    }
  };

  toggleDisplayLimit = (): void => {
    this.setState(prevState => {
      const limit =
        prevState.limit === DEFAULT_DISPLAY_AUTHOR_LIMIT
          ? this.props.authors.size
          : DEFAULT_DISPLAY_AUTHOR_LIMIT;

      trackAnalyticsEvent(
        ClickEvent.create(EventTarget.Serp.MATCHED_AUTHOR_LIST_TOGGLE, {
          more: limit === this.props.authors.size,
        })
      );

      return { limit };
    });
  };

  render(): ReactNodeish {
    const authorsToDisplay = this.props.authors.take(this.state.limit);
    const showToggleMoreLessButton = this.props.authors.size > DEFAULT_DISPLAY_AUTHOR_LIMIT;
    const showingAll = this.state.limit == this.props.authors.size;

    const matchedAuthorListCssClass = classnames(
      'flex-container',
      'flex-wrap',
      'matched-author-shoveler__list',
      {
        'matched-author-shoveler__list__short': !showingAll,
        'matched-author-shoveler__list__full': showingAll,
      }
    );

    return (
      <div className="matched-author-shoveler">
        <ul ref={this.authorCards} className={matchedAuthorListCssClass}>
          {authorsToDisplay.map((author, index) => (
            <li
              key={`matched-author-${author.id}-${index}`}
              className={`matched-author-shoveler__list-item matched-author-shoveler__list-item-${index}`}>
              <MatchedAuthorLink
                author={author}
                index={index}
                authorStat={getAuthorStat(author, this.state.authorIdToAuthorStat)}
              />
            </li>
          ))}
        </ul>
        {showToggleMoreLessButton ? (
          <button
            aria-expanded={showingAll}
            onClick={this.toggleDisplayLimit}
            className="matched-author-shoveler-toggle link-button"
            data-test-id="matched-author-shoveler-toggle">
            {this.props.authors.size > this.state.limit ? 'Show All Authors' : 'Hide Authors'}
          </button>
        ) : null}
      </div>
    );
  }
}

function getAuthorStat(
  author: MatchedAuthorRecord,
  authorIdToAuthorStat: Immutable.Map<Nullable<string>, MatchedAuthorStatRecord>
): Nullable<MatchedAuthorStatRecord> {
  const { id } = author;
  if (!id) {
    return null;
  }
  return authorIdToAuthorStat.get(id) || null;
}
