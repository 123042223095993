import {
  getProfilePictureFromGraphQL,
  PROFILE_PICTURE_FRAGMENT,
  PROFILE_PICTURE_FRAGMENT_NAME,
  ProfilePictureFromGraphQL,
  ProfilePictureRecord,
} from '@/models/user/ProfilePicture';
import { Nullable } from '@/utils/types';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type MatchedAuthorStatFromGraphQL = {
  id: number;
  citationCount: number;
  influentialPaperCount: number;
  paperCount: number;
  primaryFieldOfStudy: Nullable<string>;
  profilePicture: Nullable<ProfilePictureFromGraphQL>;
  isClaimed: boolean;
};

type Props = {
  id: string;
  citationCount: number;
  influentialPaperCount: number;
  paperCount: number;
  primaryFieldOfStudy: Nullable<string>;
  profilePicture: Nullable<ProfilePictureRecord>;
  isClaimed: boolean;
};

const defaultProps: Props = {
  id: '',
  citationCount: 0,
  influentialPaperCount: 0,
  paperCount: 0,
  primaryFieldOfStudy: null,
  profilePicture: null,
  isClaimed: false,
};

export const MatchedAuthorStatRecordFactory = Immutable.Record<Props>(defaultProps);

export type MatchedAuthorStatRecord = Immutable.RecordOf<Props>;

export function getMatchedAuthorStatFromGraphQL(
  args: MatchedAuthorStatFromGraphQL
): MatchedAuthorStatRecord {
  const { id, profilePicture, ...props } = args;
  return MatchedAuthorStatRecordFactory({
    ...props,
    id: id.toString(),
    profilePicture: profilePicture ? getProfilePictureFromGraphQL(profilePicture) : null,
  });
}

export const MATCHED_AUTHOR_STAT_FRAGMENT_NAME = 'MatchedAuthorStatRecordFragment';

export const MATCHED_AUTHOR_STAT_FRAGMENT = gql`
  ${PROFILE_PICTURE_FRAGMENT}

  fragment ${MATCHED_AUTHOR_STAT_FRAGMENT_NAME} on Author {
    id,
    citationCount,
    influentialPaperCount,
    paperCount,
    primaryFieldOfStudy,
    isClaimed,
    profilePicture {
      ...${PROFILE_PICTURE_FRAGMENT_NAME}
    }
  }
`;
