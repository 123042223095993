import SearchLayoutOptions from './SearchLayoutOptions';
import SearchResults from './SearchResults';

import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import AppHeader from '@/components/shared/layout/AppHeader';
import AuthStore from '@/stores/AuthStore';
import EventTarget from '@/analytics/constants/EventTarget';
import Page from '@/components/shared/layout/Page';
import QueryStore from '@/stores/QueryStore';
import withAnalyticsLocation from '@/components/shared/withAnalyticsLocation';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {};

type State = StateFromQueryStore;

type StateFromQueryStore = {
  isLoading: boolean;
};

const AppHeaderWithLocation = withAnalyticsLocation(EventTarget.Serp)(AppHeader);
const SearchResultsWithLocation = withAnalyticsLocation(EventTarget.Serp)(SearchResults);

export default class SearchResultsPage extends React.Component<Props, State> {
  static contextTypes = {
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...this.stateFromQueryStore(),
    };
    this.context.queryStore.registerComponent(this, () => {
      this.setState(this.stateFromQueryStore());
    });
  }

  stateFromQueryStore(): StateFromQueryStore {
    return {
      isLoading: this.context.queryStore.isLoading(),
    };
  }

  render(): React.ReactNode {
    const { isLoading } = this.state;
    const layoutOptions = new SearchLayoutOptions();
    return (
      <Page
        className="search"
        header={
          <AppHeaderWithLocation showSearchBar={true} submitSearchOnFieldOfStudyChange={true} />
        }
        footer={!isLoading}>
        <SearchResultsWithLocation layoutOptions={layoutOptions} />
      </Page>
    );
  }
}
