import Link from '@/router/Link';
import QueryRoutes from '@/utils/routing/query-routes';

import PropTypes from 'prop-types';
import React from 'react';

const ModifiedQueryNotification = ({ originalQuery, modifiedQuery }) => (
  <div className="modified-query-notification">
    <p className="original">Sorry, we couldn't find any papers about {originalQuery}.</p>
    <p>
      Results for <Link {...QueryRoutes.routeForQuery(modifiedQuery)}>{modifiedQuery}</Link>{' '}
      (without quotes):
    </p>
  </div>
);

ModifiedQueryNotification.propTypes = {
  originalQuery: PropTypes.string.isRequired,
  modifiedQuery: PropTypes.string.isRequired,
};

export default ModifiedQueryNotification;
