import CuePapersList, { MAX_PAPERS_SHOWN } from './CuePapersList';

import {
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
  CitesYourPaperCue,
  CueType,
} from '@/constants/CueType';
import { CueText, getCueText } from '@/utils/personalized-cues-util';
import { fetchCuePapers } from '@/actions/CueActionCreator';
import { getString } from '@/content/i18n';
import { heapCueBadgeButton, heapCueLearnMoreLink } from '@/analytics/attributes/personalizedCues';
import { KEY_CODE_ESC } from '@/constants/KeyCode';
import { mapHooksToProps } from '@/utils/react-utils';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { showCuePapersShelf } from '@/actions/ShelfActionCreators';
import { useAppContext, useStateFromStore } from '@/AppContext';
import Api from '@/api/Api';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLPopover, { ARROW_POSITION } from '@/components/library/popover/CLPopover';
import CueStore from '@/stores/CueStore';
import EnvInfo from '@/env/EnvInfo';
import GraphQLApi from '@/api/GraphQLApi';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import Link from '@/router/Link';
import S2Dispatcher from '@/utils/S2Dispatcher';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import type { CueDataWrapperRecord } from '@/models/CueDataWrapper';
import type { CuePaperRecord } from '@/models/CuePaper';
import type { LibraryEntryRecord } from '@/models/library/LibraryEntry';
import type { ReactNodeish } from '@/utils/types';

type PaperId = string;

// These are the event targets that are used to track metrics for cue views
// They should be specific to the originating cue source
type CueEventTargets = {
  viewCueBadgeEvent: string;
  viewCuePaperShelfEvent: string;
};

type Props = {
  cuePapersMap: Immutable.Map<PaperId, CuePaperRecord>;
  libraryEntriesMap: Immutable.Map<PaperId, LibraryEntryRecord>;
  paperIds: Immutable.List<PaperId>;
} & PropsFromParent;

type PropsFromParent = {
  resultPaperId: PaperId;
  cueBadgeData: CueDataWrapperRecord;
  paperIndex: number;
  prioritizedCue: CueType;
  cueEventTargets: CueEventTargets;
};

type State = {
  isPopoverVisible: boolean;
};

// This component will only show cues related to paper (not author).
// Author cues can have multiple CueDataWrappers associated with them since there are multiple authors per paper
// paper cues will only have one CueDataWrapper
export class PersonalizedCueBadge extends React.PureComponent<Props, State> {
  showTimer: NodeJS.Timeout;
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    cueStore: PropTypes.instanceOf(CueStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    graphQLApi: PropTypes.instanceOf(GraphQLApi).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    api: Api;
    cueStore: CueStore;
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    graphQLApi: GraphQLApi;
    libraryFolderStore: LibraryFolderStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isPopoverVisible: false,
    };
  }

  componentDidMount(): void {
    const {
      resultPaperId,
      prioritizedCue,
      paperIndex,
      paperIds,
      cueEventTargets: { viewCueBadgeEvent },
    } = this.props;

    if (!paperIds.isEmpty()) {
      trackAnalyticsEvent(
        ShowEvent.create(viewCueBadgeEvent, {
          cueType: prioritizedCue,
          isCueDisplayed: true,
          paperId: resultPaperId,
          index: paperIndex,
        })
      );
    }
  }

  closePopover = (): void => {
    this.setState({
      isPopoverVisible: false,
    });
  };

  getLibraryCueEntriesData = async (paperIds: Immutable.List<string>): Promise<void> => {
    const { libraryEntriesMap } = this.props;
    const { api } = this.context;

    const missingPaperIds = paperIds.filter(paperId => !libraryEntriesMap.get(paperId));

    if (missingPaperIds.isEmpty()) {
      return;
    }

    await api.fetchLibraryCueData(missingPaperIds);
  };

  getCuePaperData = async (paperIds: Immutable.List<string>): Promise<void> => {
    const { cuePapersMap } = this.props;
    const { graphQLApi } = this.context;

    const missingPaperIds = paperIds.filter(paperId => !cuePapersMap.get(paperId));

    if (missingPaperIds.isEmpty()) {
      return;
    }

    await fetchCuePapers(missingPaperIds, { graphQLApi });
  };

  openPopoverOrShelf = (cueTextInfo: CueText, paperIds: Immutable.List<string>): void => {
    const {
      prioritizedCue,
      cueEventTargets: { viewCuePaperShelfEvent },
    } = this.props;
    const {
      envInfo: { isMobile },
      dispatcher,
    } = this.context;

    const papersToDisplay = paperIds.take(MAX_PAPERS_SHOWN);

    switch (prioritizedCue) {
      case CitedByLibraryPaperCue:
      case CitesLibraryPaperCue:
        this.getLibraryCueEntriesData(papersToDisplay);
        break;
      case CitesYourPaperCue:
        this.getCuePaperData(papersToDisplay);
        break;
    }

    isMobile
      ? dispatcher.dispatch(
          showCuePapersShelf({
            paperIds: paperIds,
            cueDescription: cueTextInfo.cueDescription,
            cueHeader: cueTextInfo.cueLabel,
            prioritizedCue: prioritizedCue,
            viewCuePaperShelfEvent: viewCuePaperShelfEvent,
          })
        )
      : this.setState({ isPopoverVisible: true });
  };

  handleBlur = (event: React.FocusEvent<HTMLElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget as Element)) {
      this.setState({
        isPopoverVisible: false,
      });
    }
  };

  render(): ReactNodeish {
    const { isPopoverVisible } = this.state;
    const { resultPaperId, prioritizedCue, paperIndex, paperIds } = this.props;
    const { isMobile } = this.context.envInfo;

    if (paperIds.isEmpty()) {
      return null;
    }

    const cueTextInfo = getCueText(prioritizedCue, paperIds.size);

    if (!cueTextInfo) {
      return null;
    }

    const _onClickKeyDownProps = mkOnClickKeyDown({
      onClick: this.closePopover,
      overrideKeys: [KEY_CODE_ESC],
    });

    const { onKeyDown } = _onClickKeyDownProps;

    const cueBadgeAriaProps = {
      'aria-expanded': isPopoverVisible,
    };

    return (
      <div className="personalized-cue-badge">
        <CLIconButton
          data-test-id="personalized-cue-badge-button"
          ariaProps={cueBadgeAriaProps}
          className="personalized-cue-badge__button"
          onClick={
            isPopoverVisible
              ? this.closePopover
              : () => this.openPopoverOrShelf(cueTextInfo, paperIds)
          }
          icon={<Icon icon="personalization-small" width="12" height="12" />}
          label={cueTextInfo.cueLabel}
          {...heapCueBadgeButton({
            'cue-type': prioritizedCue,
            'paper-id': resultPaperId,
            index: paperIndex,
          })}
        />

        {isPopoverVisible && (
          <>
            <div className="personalized-cue-badge__overlay" {..._onClickKeyDownProps} />
            <CLPopover
              data-test-id="personalized-cue-badge-popover"
              className="personalized-cue-badge__popover"
              arrow={ARROW_POSITION.SIDE_TOP_POS_LEFT}
              onBlur={!isMobile ? this.handleBlur : undefined}
              onKeyDown={onKeyDown}>
              <CuePapersList prioritizedCue={prioritizedCue} paperIds={paperIds} />
              <hr className="personalized-cue-badge__footer-seperator" />
              <div className="flex-row personalized-cue-badge__footer">
                <p className="personalized-cue-badge__text">{cueTextInfo.cueDescription}</p>
                <span className="personalized-cue-badge__bullet" aria-hidden="true" />
                <Link
                  to="FAQ_ROOT"
                  hash="why-are-some-papers-annotated-with-a-thumbprint-icon-how-do-these-work"
                  {...heapCueLearnMoreLink({ 'cue-type': prioritizedCue })}>
                  <>
                    {getString(_ => _.personalizedCue.learnMore)}
                    <Icon
                      icon="arrow-lite"
                      width="12"
                      height="8"
                      className="personalized-cue-badge__arrow-icon"
                    />
                  </>
                </Link>
              </div>
            </CLPopover>
          </>
        )}
      </div>
    );
  }
}

export default mapHooksToProps<Props, PropsFromParent>(PersonalizedCueBadge, props => {
  const { cueStore, libraryFolderStore } = useAppContext();

  // We need to confirm that a user hasn't removed the entry from the library
  // otherwise if a person uses the back button on their browser they will still see the cue
  let paperIds = props.cueBadgeData.paperIds;
  if (props.prioritizedCue !== CitesYourPaperCue) {
    paperIds = paperIds.filter(paperId => libraryFolderStore.isPaperInLibrary(paperId));
  }

  const cueStoreState = useStateFromStore(cueStore, _ => ({
    libraryEntriesMap: _.getEntries(),
    cuePapersMap: _.getCuePapers(),
  }));

  return {
    ...props,
    ...cueStoreState,
    paperIds,
  };
});
