import { ApiResponse } from '@/api/ApiResponse';
import { getAuthorIdAsNumber } from '@/utils/author-utils';
import {
  MATCHED_AUTHOR_STAT_FRAGMENT,
  MATCHED_AUTHOR_STAT_FRAGMENT_NAME,
  MatchedAuthorStatFromGraphQL,
} from '@/models/author/MatchedAuthorStat';
import constants from '@/constants';
import GraphQLApi, { GraphQLResponse } from '@/api/GraphQLApi';

import { gql } from 'graphql-tag';

export type AuthorStatsResponseFromGraphQL = {
  authors: MatchedAuthorStatFromGraphQL[];
};

export async function fetchMatchedAuthorStats(
  { authorIds }: { authorIds: Immutable.List<number | string> },
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<ApiResponse<GraphQLResponse<AuthorStatsResponseFromGraphQL>>> {
  const query = gql`
      ${MATCHED_AUTHOR_STAT_FRAGMENT}

      query GetMatchedAuthorStats($authorIds: [Long!]!) {
        authors(authorIds: $authorIds) {
          ...${MATCHED_AUTHOR_STAT_FRAGMENT_NAME}
        }
      }
    `;
  const variables = {
    authorIds: authorIds.map(id => getAuthorIdAsNumber(id)),
  };

  const result = await graphQLApi.fetchQuery<AuthorStatsResponseFromGraphQL>({
    requestType: constants.requestTypes.GQL__AUTHOR_STATS,
    query,
    variables,
    operationName: 'GetMatchedAuthorStats',
  });
  return result;
}
