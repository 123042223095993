import {
  buildBaseClickContext,
  trackSearchResultAuthorClick,
  trackSearchResultCiteClick,
  trackSearchResultLibraryAddClick,
  trackSearchResultPaperClick,
  trackSearchResultTitleClick,
} from './SearchResultActionTracking';
import SearchLayoutOptions from './SearchLayoutOptions';

import { getPrioritizedCue } from '@/utils/personalized-cues-util';
import { PaperRecord } from '@/models/Paper';
import { QueryRecord } from '@/models/Query';
import { queryResponseHasFacets, QueryResponseRecord } from '@/models/QueryResponse';
import { SERP_CONFIG } from '@/components/shared/filter/DropdownFilterConfig';
import BrowserUtil from '@/browser';
import CLPager from '@/components/library/pager/CLPager';
import CLPaperActions from '@/components/library/paper/CLPaperActions';
import CLPaperAnalytics, {
  defaultOnClickAuthor,
  defaultOnClickCite,
  defaultOnClickLibrary,
  defaultOnClickTitle,
  defaultOnClickViewPaper,
} from '@/components/library/paper/CLPaperAnalytics';
import CLPaperAuthors from '@/components/library/paper/CLPaperAuthors';
import CLPaperCardActions from '@/components/library/paper/CLPaperCardActions';
import CLPaperControls from '@/components/library/paper/CLPaperControls';
import CLPaperMeta from '@/components/library/paper/CLPaperMeta';
import CLPaperObject, { CARD, ROW } from '@/components/library/paper/CLPaperObject';
import CLPaperStats from '@/components/library/paper/stats/CLPaperStats';
import CLPaperTitle from '@/components/library/paper/CLPaperTitle';
import DropdownFilterControls from '@/components/shared/filter/DropdownFilterControls';
import EmptySearchResults from '@/components/shared/search/EmptySearchResults';
import EnvInfo from '@/env/EnvInfo';
import EventType from '@/analytics/constants/EventTarget';
import FlexContainer from '@/components/shared/layout/FlexContainer';
import FlexItem from '@/components/shared/layout/FlexItem';
import logger from '@/logger';
import MatchedAuthorShoveler from '@/components/shared/search/MatchedAuthorShoveler';
import ModifiedQueryNotification from '@/components/shared/search/ModifiedQueryNotification';
import PersonalizedCueBadge from '@/components/shared/paper/PersonalizedCueBadge';
import QueryStore from '@/stores/QueryStore';
import ShowEvent from '@/analytics/models/ShowEvent';
import Sort from '@/constants/sort-type';
import SortSelector from '@/components/shared/common/sort/SortSelector';
import TldrToggle from '@/components/shared/search/TldrToggle';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  query: QueryRecord;
  response: QueryResponseRecord;
  isCompact?: boolean;
  layoutOptions: SearchLayoutOptions;
  isFiltering?: boolean;
};

type State = {
  showInfluentialCounts: boolean;
};

type EventData = {
  index: number;
  cueTypes?: string;
};

type TrackClickType = {
  paper: PaperRecord;
  eventData: EventData;
  compData: {
    authorId?: string;
    linkType?: string;
    linkUrl?: string;
  };
};

// consts used for tracking click events
const AUTHOR_CLICK = 1;
const CITE_CLICK = 2;
const LIBRARY_CLICK = 3;
const VIEWPAPER_CLICK = 4;
const TITLE_CLICK = 5;

export const FeedLinkContext = React.createContext({ isFirstFeedLink: false });

export default class SearchResultsList extends React.PureComponent<Props, State> {
  static contextTypes = {
    analyticsLocation: PropTypes.object,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
    router: PropTypes.object.isRequired,
  };

  constructor(...args: [any]) {
    super(...args);

    const sort = this.props.query.sort;
    this.state = {
      showInfluentialCounts: sort === Sort.INFLUENTIAL_CITATIONS.id,
    };
  }

  componentDidMount() {
    const { isCompact } = this.props;
    if (isCompact) {
      trackAnalyticsEvent(ShowEvent.create(EventType.Serp.DENSE_PAPER_VIEW));
    } else {
      trackAnalyticsEvent(ShowEvent.create(EventType.Serp.NORMAL_PAPER_VIEW));
    }
  }

  componentDidUpdate(prevProps) {
    // If we're scrolled such that we can't see any results scroll to the top of the last result
    if (
      !Immutable.is(prevProps.response, this.props.response) &&
      !this.props.response.results.isEmpty()
    ) {
      const scrollTop = BrowserUtil.scrollTop();
      const lastResult = this.refs[`search-result-${this.props.response.results.size - 1}`];
      if (lastResult) {
        // @ts-expect-error -- TODO(#36196): Solve type mismatch between React.ReactInstance and Element
        const lastResultOffset = BrowserUtil.offsetFromBody(lastResult);
        if (lastResultOffset < scrollTop) {
          // 20 is a "magic number" as to give the result a little headroom
          BrowserUtil.scrollTop(lastResultOffset - 20);
        }
      }
    }
  }

  trackClick = ({ paper, eventData, compData }: TrackClickType, clickType: number): void => {
    const { analyticsLocation, queryStore } = this.context;
    const baseClickContext = buildBaseClickContext(queryStore, {
      paperSha: paper.id,
      paperIndex: eventData.index,
    });

    switch (clickType) {
      case AUTHOR_CLICK:
        trackSearchResultAuthorClick({
          ...baseClickContext,
          authorId: parseInt(compData.authorId || ''),
        });
        defaultOnClickAuthor({ paper, analyticsLocation, eventData, compData });
        break;
      case CITE_CLICK:
        trackSearchResultCiteClick(baseClickContext);
        defaultOnClickCite({ paper, analyticsLocation, eventData });
        break;
      case LIBRARY_CLICK:
        trackSearchResultLibraryAddClick(baseClickContext);
        defaultOnClickLibrary({ paper, analyticsLocation, compData, eventData });
        break;
      case VIEWPAPER_CLICK:
        trackSearchResultPaperClick({
          ...baseClickContext,
          linkType: compData.linkType,
          linkUrl: compData.linkUrl,
        });
        defaultOnClickViewPaper({ paper, analyticsLocation, compData, eventData });
        break;
      case TITLE_CLICK:
        trackSearchResultTitleClick(baseClickContext);
        defaultOnClickTitle({ paper, analyticsLocation, eventData });
        break;
      default:
        logger.warn(`Invalid: ClickType ${clickType} is not associated with a tracking call.`);
    }
  };

  loadPage = page => {
    if (page !== this.props.query.page) {
      const node = ReactDOM.findDOMNode(this);
      if (node instanceof HTMLElement) {
        BrowserUtil.scrollTop(
          this.props.layoutOptions.scrollToTopOfPage ? 0 : BrowserUtil.offsetFromBody(node)
        );
      }
      this.context.queryStore.routeToPage(page, this.context.router);
    }
  };

  buildResultNodes(results: Immutable.List<PaperRecord>): React.ReactNode {
    const { isCompact, query } = this.props;
    const { showInfluentialCounts } = this.state;
    const {
      envInfo: { isMobile },
    } = this.context;

    return results.map((result, i) => {
      const prioritizedPaperCue = getPrioritizedCue(result.cues);
      // paper cues will only have one cueDataWrapper associated with them
      // since all the associated paperIds are put into a single cueDataWrapper
      const cueBadgeData = prioritizedPaperCue
        ? result.cues.get(prioritizedPaperCue)?.get(0)
        : null;
      const cueEventTargets = {
        viewCueBadgeEvent: EventType.Serp.CueBadge.CUE_BADGE_VIEW,
        viewCuePaperShelfEvent: EventType.Serp.CueBadge.CUE_PAPERS_SHELF_VIEW,
      };
      const index = (query.page - 1) * query.pageSize + i;

      return (
        <FeedLinkContext.Provider key={result.id} value={{ isFirstFeedLink: i === 0 }}>
          <CLPaperAnalytics
            key={result.id}
            paper={result}
            eventData={{
              index: index,
              cueTypes: !result.cues.isEmpty() ? prioritizedPaperCue : undefined,
            }}
            onClickAuthor={(_: TrackClickType) => {
              this.trackClick(_, AUTHOR_CLICK);
            }}
            onClickCite={(_: TrackClickType) => {
              this.trackClick(_, CITE_CLICK);
            }}
            onClickLibrary={(_: TrackClickType) => {
              this.trackClick(_, LIBRARY_CLICK);
            }}
            onClickViewPaper={(_: TrackClickType) => {
              this.trackClick(_, VIEWPAPER_CLICK);
            }}
            onClickTitle={(_: TrackClickType) => {
              this.trackClick(_, TITLE_CLICK);
            }}
            onHoverStats={true}>
            <CLPaperObject
              paper={result}
              className={meta => {
                return classnames('serp-papers__paper-row paper-v2-cue', {
                  'paper-row-dense': isCompact,
                  'paper-row-normal': !isCompact,
                  'search-papers__paper-card': meta === CARD,
                });
              }}
              controls={meta => {
                return (
                  <CLPaperControls
                    paper={result}
                    actions={meta === ROW ? <CLPaperActions paper={result} alert={false} /> : false}
                    stats={
                      <CLPaperStats
                        className="search-papers__stats"
                        paper={result}
                        hic={showInfluentialCounts}
                      />
                    }
                    cues={
                      !!prioritizedPaperCue &&
                      !!cueBadgeData && (
                        <PersonalizedCueBadge
                          resultPaperId={result.id}
                          cueBadgeData={cueBadgeData}
                          paperIndex={index}
                          prioritizedCue={prioritizedPaperCue}
                          cueEventTargets={cueEventTargets}
                        />
                      )
                    }
                  />
                );
              }}
              abstract={meta => {
                if (meta === ROW && isCompact) {
                  return null;
                }
                return (
                  <TldrToggle
                    paper={result}
                    query={query.queryString}
                    className={classnames({ 'tldr__paper-card': meta === CARD })}
                  />
                );
              }}
              meta={
                <CLPaperMeta
                  paper={result}
                  authors={
                    <CLPaperAuthors
                      paper={result}
                      onWhiteBackground={!!isMobile}
                      showAuthorCardPopover={true}
                    />
                  }
                />
              }
              title={<CLPaperTitle paper={result} titleTagName="h2" />}
              footer={<CLPaperCardActions paper={result} />}
            />
          </CLPaperAnalytics>
        </FeedLinkContext.Provider>
      );
    });
  }

  renderResults(): React.ReactNode {
    const {
      response: { results },
    } = this.props;

    return this.buildResultNodes(results);
  }

  renderNoResults(): React.ReactNode {
    const { querySuggestions } = this.props.response;
    const emptyQuerySuggestion = querySuggestions && !querySuggestions.isEmpty() ? false : true;
    const suggestedQueryText = !emptyQuerySuggestion ? querySuggestions.first()?.text : undefined;

    return (
      <EmptySearchResults
        query={this.props.query.queryString}
        hasFacets={queryResponseHasFacets(this.props.response)}
        suggestedQuery={suggestedQueryText}
      />
    );
  }

  onChangeSort = sort => {
    const { queryStore, router } = this.context;
    this.setState({
      showInfluentialCounts: sort === Sort.INFLUENTIAL_CITATIONS.id,
    });
    queryStore.routeToSort(sort, router);
  };

  onChangeYearFilter = (min, max) => {
    const { queryStore, router } = this.context;
    queryStore.routeToYearRange(min, max, router);
  };

  renderFreshSerpContent(): React.ReactNode {
    const { response, isFiltering, layoutOptions, query } = this.props;
    const { matchedAuthors, totalPages, modifiedQueryString } = response;

    return (
      <div className={classnames('fresh-serp', 'fresh-serp-column')}>
        <DropdownFilterControls
          className="dropdown-filters-breakpoints__serp"
          config={SERP_CONFIG}
          injectQueryStore={this.context.queryStore}
          onChangeYearFilter={this.onChangeYearFilter}
          showResultCount={true}
          doFullQueryReset={false}
          isSearchbarFocused={false}
          query={query}
          response={response}
          sortControl={
            <SortSelector
              className="dropdown-filters__sort-control"
              onChangeSort={this.onChangeSort}
              options={layoutOptions.sorts}
              sort={query.sort}
            />
          }
        />
        <FlexContainer
          layoutMed="column-reverse"
          className="centered-max-width-content"
          role="main"
          id="main-content">
          <FlexItem width="66" className="flex-item__left-column" testId="results">
            {!matchedAuthors.isEmpty() ? (
              <MatchedAuthorShoveler queryString={query.queryString} authors={matchedAuthors} />
            ) : null}
            {modifiedQueryString ? (
              <ModifiedQueryNotification
                originalQuery={response.query.queryString}
                modifiedQuery={modifiedQueryString}
              />
            ) : null}
            <div
              className={classnames('result-page', { 'is-filtering': isFiltering })}
              data-test-id="result-page"
              data-selenium-is-filtering={isFiltering || undefined}>
              {this.renderResults()}
            </div>
            <CLPager
              className="flex-right serp__pager"
              onPaginate={this.loadPage}
              pageNumber={query.page}
              totalPages={totalPages}
              maxVisiblePageButtons={4}
              data-test-id="result-page-pagination"
            />
          </FlexItem>
          <FlexItem width="33" className="flex-item__right-column" />
        </FlexContainer>
      </div>
    );
  }

  renderFreshSerpNoResults(): React.ReactNode {
    const { isFiltering } = this.props;

    return (
      <div className="fresh-serp">
        <FlexContainer className="centered-max-width-content" role="main" id="main-content">
          <FlexItem width="66" className="flex-item__left-column">
            <div className={classnames('result-page', { 'is-filtering': isFiltering })}>
              {this.renderNoResults()}
            </div>
          </FlexItem>
        </FlexContainer>
      </div>
    );
  }

  render() {
    return this.props.response.results.isEmpty()
      ? this.renderFreshSerpNoResults()
      : this.renderFreshSerpContent();
  }
}
