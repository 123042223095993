import { getPluralizedString } from '@/content/i18n';
import { MatchedAuthorStatRecord } from '@/models/author/MatchedAuthorStat';
import { useAppContext, useStateFromStore } from '@/AppContext';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import Feature from '@/weblab/Feature';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import ProfilePicture from '@/components/shared/user/ProfilePicture';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import React from 'react';

import type { MatchedAuthorRecord } from '@/models/MatchedAuthor';
import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = {
  author: MatchedAuthorRecord;
  authorStat: Nullable<MatchedAuthorStatRecord>;
  index: number;
};

export const MATCHED_AUTHOR_SHOVELER_LINK = 'matched-author-shoveler__author-link';

export default function MatchedAuthorLink(props: Props): ReactNodeish {
  const trackAuthorLinkClick = (): void => {
    trackAnalyticsEvent(
      ClickEvent.create(EventTarget.Serp.MATCHED_AUTHOR, {
        authorId: props.author.id,
        index: props.index,
      })
    );
  };

  // Determine if author profile pictures are enabled
  const { weblabStore } = useAppContext();
  const isProfilePicturesEnabled = useStateFromStore(weblabStore, _ =>
    _.isFeatureEnabled(Feature.AuthorProfilePicture)
  );
  const profilePicture = (isProfilePicturesEnabled && props?.authorStat?.profilePicture) || null;

  return (
    <CLCardContainer className="matched-author-shoveler__card" key={props.author.toString()}>
      <div className="matched-author-shoveler__author-icon">
        <ProfilePicture
          altText={props.author.name || ''}
          profilePicture={profilePicture}
          heightPx={58}
          widthPx={58}
        />
      </div>
      <ul className="matched-author-shoveler__content">
        <li>
          <Link
            to="AUTHOR_PROFILE"
            params={{ authorId: props.author.id, slug: props.author.slug }}
            onClick={trackAuthorLinkClick}
            className={MATCHED_AUTHOR_SHOVELER_LINK}
            data-test-id="matched-author-link"
            enableVisitedStyle={true}>
            <React.Fragment>
              <div
                className="matched-author-shoveler__author-title"
                data-test-id="matched-author-link-name">
                {props.author.name}
              </div>
              {props.authorStat?.isClaimed && (
                <div className="matched-author-shoveler__author-claimed-icon">
                  <Icon icon="check" height="6" width="6" />
                </div>
              )}
            </React.Fragment>
          </Link>
        </li>
        <AuthorMeta stats={props.authorStat} />
      </ul>
    </CLCardContainer>
  );
}

const AuthorMeta = ({ stats }: { stats: Nullable<MatchedAuthorStatRecord> }): ReactNodeish => {
  if (!stats) {
    // loading state
    return (
      <li className="matched-author-shoveler__metadata">
        <span className="matched-author-shoveler__metadata__placeholder matched-author-shoveler__metadata__placeholder-short">
          &nbsp;
        </span>
        <span className="matched-author-shoveler__metadata__placeholder matched-author-shoveler__metadata__placeholder-long">
          &nbsp;
        </span>
        <span className="matched-author-shoveler__metadata__placeholder matched-author-shoveler__metadata__placeholder-long">
          &nbsp;
        </span>
      </li>
    );
  } else {
    return (
      <li className="matched-author-shoveler__metadata">
        <span className="matched-author-shoveler__metadata__item">
          {stats.paperCount &&
            getPluralizedString(_ => _.serp.matchedAuthor.stats.publications, stats.paperCount)}
        </span>
        {stats.citationCount ? (
          <span className="matched-author-shoveler__metadata__item">
            {getPluralizedString(_ => _.serp.matchedAuthor.stats.citations, stats.citationCount)}
          </span>
        ) : null}
        {stats.primaryFieldOfStudy ? (
          <span className="matched-author-shoveler__metadata__item">
            {stats.primaryFieldOfStudy}
          </span>
        ) : null}
      </li>
    );
  }
};
