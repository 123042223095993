import {
  CUE_PAPER_FRAGMENT,
  CUE_PAPER_FRAGMENT_NAME,
  CuePaperFromGraphQL,
} from '@/models/CuePaper';
import { DispatchPayload } from '@/utils/S2Dispatcher';
import { Nullable } from '@/utils/types';
import constants from '@/constants';
import GraphQLApi from '@/api/GraphQLApi';

import { gql } from 'graphql-tag';

export type FetchCuePaperResponse = {
  papers: Nullable<
    {
      id: string;
    } & CuePaperFromGraphQL
  >[];
};

export async function fetchCuePapers(
  paperIds: Immutable.List<string>,
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<DispatchPayload> {
  const query = gql`
      ${CUE_PAPER_FRAGMENT}

      query GetCuePapers($paperIds: [PaperId!]) {
        papers(paperIds: $paperIds) {
          id
          ...${CUE_PAPER_FRAGMENT_NAME}
        }
      }
    `;
  const variables = {
    paperIds: paperIds,
  };
  const result = await graphQLApi.fetchQuery<FetchCuePaperResponse>({
    requestType: constants.requestTypes.GQL__CUE_PAPERS,
    query,
    variables,
    operationName: 'GetCuePapers',
  });
  return result;
}
