import SearchLayoutOptions from './SearchLayoutOptions';
import SearchResultsList from './SearchResultsList';

import { QueryRecord } from '@/models/Query';
import { QueryResponseRecord } from '@/models/QueryResponse';
import CueStore from '@/stores/CueStore';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';
import QueryStore from '@/stores/QueryStore';
import QueryStoreState, { QueryStateInst } from '@/constants/query-state';
import UserPreferencesStore, { UserPreference } from '@/stores/UserPreferencesStore';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  layoutOptions: SearchLayoutOptions;
};

type State = StateFromCueStore & StateFromQueryStore & StateFromUserPreferencesStore;

type StateFromCueStore = {
  areCuesLoading: boolean;
};
type StateFromQueryStore = {
  queryStoreState: QueryStateInst;
  query: QueryRecord;
  response: QueryResponseRecord;
};

type StateFromUserPreferencesStore = {
  isCompact: boolean;
};

export default class SearchResults extends React.PureComponent<Props, State> {
  static contextTypes = {
    cueStore: PropTypes.instanceOf(CueStore).isRequired,
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
    userPrefsStore: PropTypes.instanceOf(UserPreferencesStore).isRequired,
  };

  declare context: {
    cueStore: CueStore;
    queryStore: QueryStore;
    userPrefsStore: UserPreferencesStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...this.stateFromCueStore(),
      ...this.stateFromQueryStore(),
      ...this.stateFromUserPreferencesStore(),
    };
    this.context.cueStore.registerComponent(this, () => {
      this.setState(this.stateFromCueStore());
    });
    this.context.queryStore.registerComponent(this, () => {
      this.setState(this.stateFromQueryStore());
    });
    this.context.userPrefsStore.registerComponent(this, () => {
      this.setState(this.stateFromUserPreferencesStore());
    });
  }

  stateFromCueStore(): StateFromCueStore {
    const { cueStore } = this.context;
    return {
      areCuesLoading: cueStore.areCuesLoading(),
    };
  }

  stateFromQueryStore(): StateFromQueryStore {
    return {
      queryStoreState: this.context.queryStore._state,
      query: this.context.queryStore._query,
      response: this.context.queryStore._response,
    };
  }

  stateFromUserPreferencesStore(): StateFromUserPreferencesStore {
    return {
      isCompact: !!this.context.userPrefsStore.getLocalPreference(UserPreference.DENSE_PAPER_VIEW),
    };
  }

  render(): React.ReactNode {
    const { areCuesLoading, queryStoreState, query, response, isCompact } = this.state;

    if (
      queryStoreState === QueryStoreState.LOADING ||
      queryStoreState === QueryStoreState.INITIALIZED ||
      areCuesLoading
    ) {
      return (
        <section className="content" role="main" id="main-content">
          <div className="container loading-controls flex-row-vcenter">
            <LoadingIndicator testId="icon-loading-serp" /> Loading results
          </div>
        </section>
      );
    } else {
      return (
        <SearchResultsList
          isCompact={isCompact}
          query={query}
          response={response}
          layoutOptions={this.props.layoutOptions}
          isFiltering={queryStoreState === QueryStoreState.FILTERING}
        />
      );
    }
  }
}
